import dayjs from "dayjs"
import React, { useState, useEffect } from "react"
import { useMutation } from "urql"

import { UPSERT_PERSONAL_EVENT } from "../../../utils/mutations"
import { Button } from "../../shared/Buttons"
import DatePicker from "../../shared/DatePicker"
import { CheckBox, Input, Label, TimePicker } from "../../shared/Inputs"
import { AnimatedModal } from "../../shared/Modal"

const DELETE_PERSONAL_EVENT = `
  mutation DeletePersonalEvent ($id: ID!) {
    deletePersonalEvent (id: $id) {
      result
      errors
    }
  }
`

const combineDateAndTime = (date, time) => {
  const tempDate = new Date(date)
  tempDate.setHours(parseInt(time.split(":")[0]), parseInt(time.split(":")[1]), 0)
  return dayjs(tempDate).format("YYYY-MM-DDTHH:mm:ssZ")
}

export default function PersonalEventForm({ onCreate, onDelete, personalEvent, clickedTime }) {
  const [name, setName] = useState(personalEvent?.title || "")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [startTime, setStartTime] = useState("10:00")
  const [endTime, setEndTime] = useState("11:00")
  const [allDay, setAllDay] = useState(personalEvent?.allDay || false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [, upsertPersonalEvent] = useMutation(UPSERT_PERSONAL_EVENT)
  const [, deletePersonalEvent] = useMutation(DELETE_PERSONAL_EVENT)

  useEffect(() => {
    let newStartDate, newEndDate

    if (personalEvent) {
      newStartDate = new Date(personalEvent.start)
      newEndDate = new Date(personalEvent.allDay ? dayjs(personalEvent.end).subtract(1, "day") : personalEvent.end)
    } else if (clickedTime) {
      newStartDate = new Date(clickedTime)
      newEndDate = new Date(clickedTime)
      newEndDate.setHours(newEndDate.getHours() + 1)
    } else {
      newStartDate = new Date()
      newEndDate = new Date()
      newEndDate.setHours(newEndDate.getHours() + 1)
    }

    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setStartTime(dayjs(newStartDate).format("HH:mm"))
    setEndTime(dayjs(newEndDate).format("HH:mm"))
    setName(personalEvent?.title || "")
    setAllDay(personalEvent?.allDay || false)
  }, [personalEvent, clickedTime])

  const personalEventId = personalEvent ? Number(personalEvent.id.split("-").slice(-1)[0]) : undefined

  return (
    <div className="relative">
      <div className="mb-4 flex flex-col">
        <Input
          id="event-name"
          value={name}
          placeholder="Event name (optional)"
          onChange={(event) => {
            setName(event.target.value)
          }}
        />
        <div className="mt-2">
          <CheckBox
            id="all-day-checkbox"
            label="All-Day"
            name="All-Day"
            checked={allDay}
            onChange={() => {
              setAllDay(!allDay)
            }}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between gap-4">
        <div className="mb-4 flex w-1/2 flex-col">
          <Label>Start date</Label>
          <DatePicker className="w-full" selected={startDate} onChange={(date) => setStartDate(date)} />
        </div>
        <div className="flex  w-1/2 flex-col">
          <Label>End date</Label>
          <DatePicker className="w-full" selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} />
        </div>
      </div>
      {!allDay && (
        <div className="flex flex-row justify-between gap-4">
          <div className="w-1/2">
            <Label htmlFor="personal-event-start-time">Start time</Label>
            <TimePicker
              id="personal-event-start-time"
              value={startTime}
              valueFormat="H:mm"
              showFifteenIncrements={true}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </div>
          <div className="w-1/2">
            <Label htmlFor="personal-event-end-time">End time</Label>
            <TimePicker
              id="personal-event-end-time"
              value={endTime}
              valueFormat="H:mm"
              showFifteenIncrements={true}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="mt-10 flex w-full items-end justify-end gap-4 border-t border-gray-light py-4">
        {personalEvent && (
          <Button className="sm:w-full" type="warning" onClick={() => setShowCancelModal(true)}>
            Cancel Event
          </Button>
        )}
        <Button
          type="primary"
          className="sm:w-full"
          onClick={() => {
            const variables = {
              name,
              allDay,
              startsAt: combineDateAndTime(startDate, startTime),
              endsAt: combineDateAndTime(endDate, endTime),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            if (personalEvent) {
              variables.id = personalEventId
            }
            upsertPersonalEvent(variables).then((result) => {
              if (result.error) {
                console.error(result.error)
              } else {
                onCreate(result.data.upsertPersonalEvent.personalEvent)
              }
            })
          }}>
          Save
        </Button>
      </div>
      <AnimatedModal
        visible={showCancelModal}
        hideModal={() => setShowCancelModal(false)}
        header="Cancel Event"
        showFooter={true}
        cancelButtonCopy="Go back"
        actionButtonCopy="Cancel"
        actionButtonType="destructive"
        onSave={() => {
          deletePersonalEvent({ id: personalEventId }).then((response) => {
            if (response.data.deletePersonalEvent?.result === "success") {
              setShowCancelModal(false)
              onDelete(personalEventId)
            } else {
              console.error(response)
            }
          })
        }}>
        <p>Are you sure you want to cancel this personal event?</p>
      </AnimatedModal>
    </div>
  )
}
