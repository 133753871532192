require("@rails/ujs").start()
require("channels/consumer")
require("swiper")
require("../src/application/setup")
require("../src/application/misc")
require("../src/application/amplitude")
require("../src/application/tabs")
require("../src/application/forms")
require("../src/application/quiz")
require("../src/application/toggle-controls")
require("../src/application/intlTelInput")
require("../src/application/utils")

import "controllers"
import "../stylesheets/application.css.sass"
import "react-tooltip/dist/react-tooltip.css"

import ReactOnRails from "react-on-rails"
import { setLicenseKey } from "survey-core"

import { wrapWithErrorBoundary } from "../@core/HoneyBadgerErrorBoundary"
import ClientBookings from "../components/client_bookings/ClientBookings"
import ClientUploadButton from "../components/ClientUploadButton"
import BrowseTherapiesCTA from "../components/header/BrowseTherapiesCTA"
import MobileMenu from "../components/header/MobileMenu"
import SearchBar from "../components/header/SearchBar"
import InviteClients from "../components/invite_clients/InviteClients"
import ReviewBookNext from "../components/leave_review/ReviewBookNext"
import ReviewClientInfo from "../components/leave_review/ReviewClientInfo"
import ReviewConfirm from "../components/leave_review/ReviewConfirm"
import ReviewEndorsements from "../components/leave_review/ReviewEndorsements"
import ReviewIntro from "../components/leave_review/ReviewIntro"
import ReviewRating from "../components/leave_review/ReviewRating"
import ReviewTextReview from "../components/leave_review/ReviewTextReview"
import ListPracticeCTA from "../components/ListPracticeCTA"
import PortalHeader from "../components/PortalHeader"
import ProCalendar from "../components/pro_portal/calendar/ProCalendar"
import ClientDirectory from "../components/pro_portal/clients/ClientDirectory"
import DownloadMobileApp from "../components/pro_portal/dashboard/DownloadMobileApp"
import PaymentDirectory from "../components/pro_portal/payments/PaymentDirectory"
import ProInbox from "../components/pro_portal/ProInbox"
import ProfilePhoneNumber from "../components/ProfilePhoneNumber"
import ClientUpload from "../components/shared/ClientUpload"
import EnterPhone from "../components/shared/EnterPhone"
import SideNavigation from "../components/SideNavigation"
import BookableEvents from "../pages/BookableEvents"
import ClientBillingPage from "../pages/Client/ClientBillingPage"
import ClientIntakeForm from "../pages/Client/ClientIntakeForm"
import ClientNotes from "../pages/Client/ClientNotes"
import ClientOverview from "../pages/Client/ClientOverview"
import HomePage from "../pages/HomePage"
import IntakeForm from "../pages/IntakeForm"
import IntakeFormsPage from "../pages/IntakeFormsPage"
import ManageAppointmentsConsumer from "../pages/ManageAppointmentsConsumer"
import ManagePaymentMethods from "../pages/ManagePaymentMethods/ManagePaymentMethods"
import PersonalSettings from "../pages/PersonalSettings"
import PortalGiftCardTemplatesPage from "../pages/PortalGiftCardTemplates/PortalGiftCardTemplatesPage"
import PortalServicesLocations from "../pages/PortalServices/PortalServicesLocations"
import PortalServicesPage from "../pages/PortalServices/PortalServicesPage"
import ProCommunicationSettings from "../pages/PortalSettings/Communications/ProCommunicationSettings"
import ProCommunicationTemplates from "../pages/PortalSettings/Communications/ProCommunicationTemplates"
import ConferencingSettingsPage from "../pages/PortalSettings/ConferencingSettings"
import PaypalSettings from "../pages/PortalSettings/Payments/PaypalSettings"
import SquarePaymentsSettings from "../pages/PortalSettings/Payments/SquarePaymentsSettings"
import StripePaymentsSettings from "../pages/PortalSettings/Payments/StripePaymentsSettings"
import ProBookingSettings from "../pages/PortalSettings/ProBookingSettings"
import ProCalendarSettings from "../pages/PortalSettings/ProCalendarSettings"
import ProMainSettings from "../pages/PortalSettings/ProMainSettings"
import ProPaymentSettings from "../pages/PortalSettings/ProPaymentSettings"
import PractitionerLandingPage from "../pages/PractitionerLandingPage/PractitionerLandingPage"
import CombinedInformationPage from "../pages/ProfileEdit/CombinedInformationPage"
import PersonalInformationPage from "../pages/ProfileEdit/PersonalInformationPage"
import PracticeInformationPage from "../pages/ProfileEdit/PracticeInformationPage"
import ProfileEditCredentialsV2 from "../pages/ProfileEdit/ProfileEditCredentialsV2"
import ProfileEditAudience from "../pages/ProfileEditAudience"
import ProfileEditCredentials from "../pages/ProfileEditCredentials"
import ProfileEditDetails from "../pages/ProfileEditDetails"
import ProfileEditEndorsements from "../pages/ProfileEditEndorsements"
import ProfileEditLocations from "../pages/ProfileEditLocations"
import ProfileEditPhotos from "../pages/ProfileEditPhotos"
import ProfileEditServices from "../pages/ProfileEditServices"
import ProProfile from "../pages/ProProfile"
import ProProfileV2 from "../pages/ProProfileV2"
import ProReviews from "../pages/ProReviews"
import ProReviewsCompletedPage from "../pages/ProReviews/ProReviewsCompletedPage"
import ProReviewsEndorsementsPage from "../pages/ProReviews/ProReviewsEndorsementsPage"
import ProReviewsImportPage from "../pages/ProReviews/ProReviewsImportPage"
import ProReviewsPendingPage from "../pages/ProReviews/ProReviewsPendingPage"
import ProReviewsRequestPage from "../pages/ProReviews/ProReviewsRequestPage"
import ReferralProgramPage from "../pages/ReferralProgramPage"
import SearchPage from "../pages/SearchPage"
import TopNavSearchBox from "../pages/SearchPage/TopNavSearchBox"
import WebsiteBuilderPage from "../pages/WebsiteBuilder/WebsiteBuilderPage"
import WebsiteContentPage from "../pages/WebsiteBuilder/WebsiteContentPage"
import WebsiteDash from "../pages/WebsiteDash"
import WebsiteSettings from "../pages/WebsiteSettings/WebsiteSettings"
import dayJsPlugins from "../utils/dayJsPlugins" // eslint-disable-line no-unused-vars

document.addEventListener("DOMContentLoaded", function () {
  // Note: we've removed turbolinks, but much of the legacy code still listens for this event. Eventually we want to remove even these events.
  document.dispatchEvent(new Event("turbolinks:load", { bubbles: true, cancelable: true }))
})

setLicenseKey(
  "ZjY4NTJmMjItNzgzNS00MWY0LWFjNzktMTZhNjFhOTUzNTJkJmRvbWFpbnM6aGVhbC5tZSxsb2NhbGhvc3Q6MzAwMDsxPTIwMjUtMDctMTksMj0yMDI1LTA3LTE5LDQ9MjAyNS0wNy0xOQ=="
)

const components = {
  ProfilePhoneNumber,
  InviteClients,
  ListPracticeCTA,
  DownloadMobileApp,
  ProCalendar,
  ProInbox,
  ProMainSettings,
  ProCalendarSettings,
  ProReviews,
  BrowseTherapiesCTA,
  SearchBar,
  MobileMenu,
  ClientDirectory,
  PaymentDirectory,
  ClientUpload,
  EnterPhone,
  ManageAppointmentsConsumer,
  ClientBookings,
  ProCommunicationSettings,
  ProCommunicationTemplates,
  SideNavigation,
  ClientUploadButton,
  ProfileEditDetails,
  ProfileEditCredentials,
  ProfileEditEndorsements,
  ProfileEditPhotos,
  ProfileEditServices,
  ProfileEditLocations,
  ProfileEditAudience,
  ProPaymentSettings,
  StripePaymentsSettings,
  SquarePaymentsSettings,
  PaypalSettings,
  ProBookingSettings,
  PortalHeader,
  PersonalSettings,
  ReviewIntro,
  ReviewClientInfo,
  ReviewRating,
  ReviewEndorsements,
  ReviewTextReview,
  ReviewBookNext,
  ReviewConfirm,
  ManagePaymentMethods,
  HomePage,
  ProProfile,
  ProProfileV2,
  SearchPage,
  TopNavSearchBox,
  BookableEvents,
  ClientNotes,
  IntakeFormsPage,
  IntakeForm,
  ClientOverview,
  ClientIntakeForm,
  ClientBillingPage,
  WebsiteBuilderPage,
  WebsiteContentPage,
  WebsiteDash,
  WebsiteSettings,
  PortalServicesPage,
  PortalServicesLocations,
  ProReviewsCompletedPage,
  ProReviewsImportPage,
  ProReviewsPendingPage,
  ProReviewsRequestPage,
  ProReviewsEndorsementsPage,
  PersonalInformationPage,
  ProfileEditCredentialsV2,
  PracticeInformationPage,
  PractitionerLandingPage,
  ReferralProgramPage,
  CombinedInformationPage,
  PortalGiftCardTemplatesPage,
  ConferencingSettingsPage
}

Object.entries(components).forEach(([name, Component]) => {
  ReactOnRails.register({
    [name]: wrapWithErrorBoundary(Component)
  })
})
