import dayjs from "dayjs"
import React, { useEffect, useState } from "react"

import { useManualBooking } from "../../../contexts/ManualBookingContext"
import { Flyout } from "../../shared/Layout"
import { RadioWithLabel } from "../../shared/RadioButtons"

import ClientEventForm from "./ClientEventForm"
import PersonalEventForm from "./PersonalEventForm"

export default function AppointmentFlyout({
  personalEvent,
  calendarEvent,
  clickedTime,
  onAppointmentChanged,
  onCreatePersonal,
  onDeletePersonal,
  onRescheduleSuccess,
  onAppointmentCancelled,
  onRecurringAppointmentsChanged,
  visible,
  closeFlyout
}) {
  const { appointment } = useManualBooking()

  const [createPersonalEvent, setCreatePersonalEvent] = useState(!!personalEvent)

  useEffect(() => {
    if (personalEvent) {
      setCreatePersonalEvent(true)
    }
  }, [personalEvent])

  const sidebarHeader = () => {
    if (calendarEvent) return calendarEvent.title

    if (createPersonalEvent) {
      return personalEvent ? "Edit personal event" : "Create personal event"
    }

    if (!appointment) return "Create appointment"

    let upcoming = false
    let pending = false

    if (appointment) {
      upcoming = new Date(appointment.startsAt) >= new Date()
      pending = appointment.state === "pending"
    } else if (personalEvent) {
      upcoming = new Date(personalEvent.startDate) >= new Date()
    }

    if (pending && upcoming) return "New appointment request"
    if (upcoming) return "Upcoming appointment"
    return "Past appointment"
  }

  const handlePersonalEventCreate = (personalEvent) => {
    setCreatePersonalEvent(false)
    onCreatePersonal(personalEvent)
  }

  const handleCloseFlyout = () => {
    closeFlyout()
    setCreatePersonalEvent(false)
  }

  return (
    <Flyout header={sidebarHeader()} visible={visible} closeFlyout={handleCloseFlyout}>
      <div className="relative">
        {!appointment && !personalEvent && !calendarEvent && (
          <>
            <div className="mb-4 flex flex-row gap-4">
              <RadioWithLabel
                value="client"
                name="event-type"
                label="Client"
                checked={!createPersonalEvent}
                onChange={(e) => {
                  if (e.target.value === "client") {
                    setCreatePersonalEvent(false)
                  }
                }}
              />
              <RadioWithLabel
                value="personal"
                name="event-type"
                label="Personal"
                checked={createPersonalEvent}
                onChange={(e) => {
                  if (e.target.value === "personal") {
                    setCreatePersonalEvent(true)
                  }
                }}
              />
            </div>
          </>
        )}

        {createPersonalEvent ? (
          <PersonalEventForm
            onCreate={handlePersonalEventCreate}
            onDelete={onDeletePersonal}
            personalEvent={personalEvent}
            clickedTime={clickedTime}
          />
        ) : calendarEvent ? (
          <div className="grid grid-cols-[auto,1fr] gap-4">
            <div className="font-bold">When</div>
            <div>
              {dayjs(calendarEvent.start).format("dddd, MMM Do, YYYY [at] h:mm A")} -{" "}
              {dayjs(calendarEvent.end).format("h:mm A z")}
            </div>
            {calendarEvent.extendedProps.location && (
              <>
                <div className="font-bold">Where</div>
                <div>{calendarEvent.extendedProps.location}</div>
              </>
            )}
            <div className="font-bold">Calendar</div>
            <div>{calendarEvent.extendedProps.calendarName}</div>
            {calendarEvent.extendedProps.about && (
              <>
                <div className="font-bold">About</div>
                <div>{calendarEvent.extendedProps.about}</div>
              </>
            )}
          </div>
        ) : (
          <ClientEventForm
            onAppointmentChanged={onAppointmentChanged}
            onRescheduleSuccess={onRescheduleSuccess}
            onAppointmentCancelled={onAppointmentCancelled}
            onRecurringAppointmentsChanged={onRecurringAppointmentsChanged}
          />
        )}
      </div>
    </Flyout>
  )
}
