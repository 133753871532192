import { Combobox } from "@headlessui/react"
import { MapPinIcon } from "@heroicons/react/24/solid"
import capitalize from "lodash/capitalize"
import React from "react"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { twMerge } from "tailwind-merge"

import {
  BASE_INPUT_CLASSNAMES,
  DROPDOWN_CLASSNAMES,
  DROPDOWN_OPTION_CLASSNAMES,
  Label
} from "../../components/shared/Inputs"
import { Divider } from "../../components/shared/Layout"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import { stripUSAFromString } from "../../utils/utils"

const LocationInput = ({ bookableEvent, setBookableEvent, previousLocations }) => {
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    debounce: 100
  })

  const onChange = (location) => {
    setBookableEvent((prev) => ({ ...prev, location }))
  }

  const value = bookableEvent.location || ""

  return (
    <div>
      <Label htmlFor="location-input">Location</Label>
      <Combobox
        id="location-input"
        as="div"
        className="sm:flex-1"
        value={value}
        onChange={(item) => {
          if (typeof item === "string") {
            onChange(item)
          } else if (item) {
            placesService.getDetails({ placeId: item.place_id }, (place) => {
              const newMapCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
              const event = new CustomEvent("newMapCenter", { detail: newMapCenter })
              document.dispatchEvent(event)
              onChange(item.description)
            })
          }
        }}>
        <Combobox.Button className="w-full">
          <div className="relative">
            <Combobox.Input
              onChange={(evt) => {
                onChange(evt.target.value)
                getPlacePredictions({ input: evt.target.value })
              }}
              className={BASE_INPUT_CLASSNAMES}
              placeholder="Location"
            />
            {isPlacePredictionsLoading ? (
              <LoadingSpinner className="absolute right-2 top-2 py-0" spinnerClassName="h-5 w-5" />
            ) : (
              <MapPinIcon className="absolute right-1.5 top-1.5 h-6 w-6 text-gray-dark opacity-[0.64] sm:top-[7px]" />
            )}
          </div>
        </Combobox.Button>
        <Combobox.Options className={twMerge(DROPDOWN_CLASSNAMES, "fixed max-w-[430px] sm:w-full")}>
          {previousLocations?.length > 0 && <div className="px-3 font-bold">My locations</div>}
          {previousLocations?.map((location) => (
            <Combobox.Option key={location} className={DROPDOWN_OPTION_CLASSNAMES} value={location}>
              {location}
            </Combobox.Option>
          ))}
          {previousLocations?.length > 0 && <Divider />}
          {placePredictions?.length > 0 &&
            !isPlacePredictionsLoading &&
            placePredictions.map((item) => (
              <Combobox.Option key={item.description} className={DROPDOWN_OPTION_CLASSNAMES} value={item}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: stripUSAFromString(item.description).replace(
                      new RegExp(value, "i"),
                      `<mark style='font-weight: bold'>${capitalize(value)}</mark>`
                    )
                  }}
                />
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </Combobox>
    </div>
  )
}

export default LocationInput
