import dayjs from "dayjs"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useMutation } from "urql"

import {
  DELETE_BOOKABLE_EVENT_MUTATION,
  REMOVE_BOOKABLE_EVENT_PHOTO_MUTATION,
  UPDATE_BOOKABLE_EVENT_PHOTO_MUTATION,
  UPSERT_BOOKABLE_EVENT_MUTATION
} from "./mutations"

const defaultNewEvent = {
  name: "",
  startsAt: dayjs()
    .set("hour", dayjs().get("hour") + 1)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .toISOString(),
  endsAt: dayjs()
    .set("hour", dayjs().get("hour") + 2)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .toISOString(),
  allDay: false,
  timeZone: null,
  location: "",
  amountCents: 0
}

export const BookableEventsContext = createContext()

export const BookableEventsProvider = ({ children, bookableEvents: bookableEventsProp, previousLocations }) => {
  const [showFlyout, setShowFlyout] = useState(false)
  const [bookableEvents, setBookableEvents] = useState(bookableEventsProp)
  const [googlePlacesLoaded, setGooglePlacesLoaded] = useState(false)
  const [bookableEvent, setBookableEvent] = useState(defaultNewEvent)
  const [defaultTimeZone, setDefaultTimeZone] = useState(null)

  const [{ fetching: upsertMutationFetching }, upsertBookableEvent] = useMutation(UPSERT_BOOKABLE_EVENT_MUTATION)
  const [{ fetching: photoMutationFetching }, updatePhoto] = useMutation(UPDATE_BOOKABLE_EVENT_PHOTO_MUTATION)
  const [{ fetching: deleteMutationFetching }, deleteBookableEvent] = useMutation(DELETE_BOOKABLE_EVENT_MUTATION)
  const [{ fetching: removePhotoMutationFetching }, removePhoto] = useMutation(REMOVE_BOOKABLE_EVENT_PHOTO_MUTATION)

  const fetching =
    upsertMutationFetching || photoMutationFetching || deleteMutationFetching || removePhotoMutationFetching

  useEffect(() => {
    // do this in an effect to avoid SSR rehydration issues
    setBookableEvent((event) => ({ ...event, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }))
    setDefaultTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)

    async function initPlaces() {
      await window.google.maps.importLibrary("places")
      setGooglePlacesLoaded(true)
    }

    initPlaces()
  }, [])

  const updateBookableEvents = (newEvent) => {
    setBookableEvents((prevEvents) => {
      const newEvents = [...prevEvents]
      const index = newEvents.findIndex((s) => s.id === newEvent.id)
      if (index !== -1) {
        newEvents[index] = newEvent
      } else {
        newEvents.push(newEvent)
      }

      return newEvents
    })
  }

  const value = {
    bookableEvents,
    setBookableEvents,
    showFlyout,
    setShowFlyout,
    bookableEvent,
    setBookableEvent,
    defaultTimeZone,
    defaultNewEvent,
    setDefaultTimeZone,
    previousLocations,
    googlePlacesLoaded,
    fetching,
    upsertBookableEvent,
    updatePhoto,
    deleteBookableEvent,
    removePhoto,
    updateBookableEvents
  }

  return <BookableEventsContext.Provider value={value}>{children}</BookableEventsContext.Provider>
}

export const useBookableEvents = () => useContext(BookableEventsContext)
