import dayjs from "dayjs"
import React, { useState } from "react"

import { Button } from "../../components/shared/Buttons"
import CurrencyInput from "../../components/shared/CurrencyInput"
import DatePicker from "../../components/shared/DatePicker"
import { CheckBox, Input, Label, Select, TextArea, TimePicker } from "../../components/shared/Inputs"
import { Divider, Flyout } from "../../components/shared/Layout"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import { formatPrice, unformatPrice } from "../../utils/utils"

import { useBookableEvents } from "./BookableEventsContext"
import LocationInput from "./LocationInput"
import PhotoUpload from "./PhotoUpload"

const BookableEventsFlyout = () => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const {
    bookableEvents,
    setBookableEvents,
    showFlyout,
    setShowFlyout,
    bookableEvent,
    setBookableEvent,
    defaultNewEvent,
    previousLocations,
    googlePlacesLoaded,
    fetching,
    upsertBookableEvent,
    deleteBookableEvent,
    updateBookableEvents
  } = useBookableEvents()
  const { showToast } = useToast()

  const editingExistingEvent = !!bookableEvent.id
  const saveEnabled = !fetching && bookableEvent.name?.length > 0 && bookableEvent.location?.length > 0

  const onSave = () => {
    upsertBookableEvent({ ...bookableEvent }).then((result) => {
      if (result?.data?.upsertBookableEvent?.result === "success") {
        const newEvent = result.data.upsertBookableEvent.bookableEvent
        newEvent.id = Number(newEvent.id)
        updateBookableEvents(newEvent)
        setShowFlyout(false)
        showToast(`Your event '${newEvent.name}' was saved successfully`)
      } else {
        console.error(result)
        let errorMessage = "There was an error saving your event"
        if (result.data?.upsertBookableEvent?.errors) errorMessage += `: ${result.data.upsertBookableEvent.errors}`
        showToast({ type: "error", content: errorMessage })
      }
    })
  }

  return (
    <>
      <Flyout
        header="Create event"
        visible={showFlyout}
        closeFlyout={() => setShowFlyout(false)}
        onSave={onSave}
        footer={
          <div className={`flex w-full items-center ${editingExistingEvent ? "justify-between" : "justify-end"}`}>
            {editingExistingEvent && (
              <Button type="destructive" onClick={() => setDeleteModalVisible(true)} disabled={fetching}>
                Delete
              </Button>
            )}
            <div className="flex gap-4">
              <Button type="tertiary" onClick={() => setShowFlyout(false)} disabled={fetching}>
                Cancel
              </Button>
              <Button type="primary" onClick={onSave} disabled={fetching || !saveEnabled}>
                Save
              </Button>
            </div>
          </div>
        }>
        <div className="mb-4 flex flex-col">
          <Input
            id="event-name"
            value={bookableEvent.name}
            placeholder="Event name"
            onChange={(e) => setBookableEvent((event) => ({ ...event, name: e.target.value }))}
          />
          <div className="mt-2">
            <CheckBox
              id="all-day-checkbox"
              label="All-Day"
              name="All-Day"
              checked={bookableEvent.allDay}
              onChange={() => {
                if (!bookableEvent.allDay) {
                  const startsAt = dayjs(bookableEvent.startsAt).tz(bookableEvent.timeZone).startOf("day").toISOString()
                  const endsAt = dayjs(bookableEvent.endsAt).tz(bookableEvent.timeZone).endOf("day").toISOString()
                  setBookableEvent((event) => ({ ...event, allDay: !event.allDay, startsAt, endsAt }))
                } else {
                  setBookableEvent((event) => ({
                    ...event,
                    allDay: !event.allDay,
                    startsAt: defaultNewEvent.startsAt,
                    endsAt: defaultNewEvent.endsAt
                  }))
                }
              }}
            />
          </div>
        </div>
        {bookableEvent.timeZone && (
          <>
            <div className="flex flex-row justify-between gap-4">
              <div className="mb-4 flex w-1/2 flex-col">
                <Label>Start date</Label>
                <DatePicker
                  className="w-full"
                  selected={new Date(dayjs(bookableEvent.startsAt).tz(bookableEvent.timeZone))}
                  onChange={(date) => setBookableEvent((event) => ({ ...event, startsAt: date, endsAt: date }))}
                />
              </div>
              <div className="flex  w-1/2 flex-col">
                <Label>End date</Label>
                <DatePicker
                  className="w-full"
                  selected={new Date(dayjs(bookableEvent.endsAt).tz(bookableEvent.timeZone))}
                  onChange={(date) => setBookableEvent((event) => ({ ...event, endsAt: date }))}
                />
              </div>
            </div>
            {!bookableEvent.allDay && (
              <div className="flex flex-row justify-between gap-4">
                <div className="w-1/2">
                  <Label htmlFor="bookable-event-start-time">Start time</Label>
                  <TimePicker
                    id="bookable-event-start-time"
                    value={dayjs(bookableEvent.startsAt).tz(bookableEvent.timeZone).format("HH:mm")}
                    valueFormat="HH:mm"
                    showFifteenIncrements={true}
                    onChange={(e) => {
                      const [hour, minute] = e.target.value.split(":")
                      const duration = dayjs(bookableEvent.endsAt).diff(dayjs(bookableEvent.startsAt), "minute")
                      setBookableEvent((event) => ({
                        ...event,
                        startsAt: dayjs(event.startsAt).set("hour", hour).set("minute", minute).toDate(),
                        endsAt: dayjs(event.startsAt)
                          .set("hour", hour)
                          .set("minute", minute)
                          .add(duration, "minute")
                          .toDate()
                      }))
                    }}
                  />
                </div>
                <div className="w-1/2">
                  <Label htmlFor="bookable-event-end-time">End time</Label>
                  <TimePicker
                    id="bookable-event-end-time"
                    value={dayjs(bookableEvent.endsAt).tz(bookableEvent.timeZone).format("HH:mm")}
                    valueFormat="HH:mm"
                    showFifteenIncrements={true}
                    onChange={(e) => {
                      const [hour, minute] = e.target.value.split(":")
                      setBookableEvent((event) => ({
                        ...event,
                        endsAt: dayjs(event.endsAt).set("hour", hour).set("minute", minute).toDate()
                      }))
                    }}
                  />
                </div>
              </div>
            )}
            <div className="mt-4">
              <Label htmlFor="time-zone">Time zone</Label>
              <Select
                value={bookableEvent.timeZone}
                onChange={(e) =>
                  setBookableEvent((event) => {
                    const startsAt = dayjs(event.startsAt)
                      .tz(e.target.value)
                      .set("hour", dayjs(event.startsAt).tz(event.timeZone).get("hour"))
                      .set("minute", dayjs(event.startsAt).tz(event.timeZone).get("minute"))
                      .toISOString()
                    const endsAt = dayjs(event.endsAt)
                      .tz(e.target.value)
                      .set("hour", dayjs(event.endsAt).tz(event.timeZone).get("hour"))
                      .set("minute", dayjs(event.endsAt).tz(event.timeZone).get("minute"))
                      .toISOString()
                    return {
                      ...event,
                      timeZone: e.target.value,
                      startsAt,
                      endsAt
                    }
                  })
                }
                id="time-zone">
                {Intl.supportedValuesOf("timeZone").map((tz) => (
                  <option key={tz} value={tz}>
                    {tz}
                  </option>
                ))}
              </Select>
            </div>
          </>
        )}
        {googlePlacesLoaded && (
          <div className="mt-4">
            <LocationInput
              bookableEvent={bookableEvent}
              previousLocations={previousLocations}
              setBookableEvent={setBookableEvent}
            />
            <Typography variant="micro">Enter a URL for virtual events or an address for in person</Typography>
          </div>
        )}
        <Divider />
        <div>
          <div className="flex justify-between">
            <Label htmlFor="description">Description</Label>
            {!bookableEvent.description && <div className="text-sm text-gray-dark">Optional</div>}
          </div>
          <TextArea
            id="description"
            value={bookableEvent.description || ""}
            onChange={(e) => setBookableEvent((event) => ({ ...event, description: e.target.value }))}
          />
        </div>
        <div className="mt-4">
          <div className="flex justify-between">
            <Label htmlFor="description">Instructions</Label>
            {!bookableEvent.instructions && <div className="text-sm text-gray-dark">Optional</div>}
          </div>
          <TextArea
            id="instructions"
            value={bookableEvent.instructions || ""}
            onChange={(e) => setBookableEvent((event) => ({ ...event, instructions: e.target.value }))}
          />
          <Typography variant="micro" className="-mt-1" as="div">
            Instructions for attendees. We&apos;ll email these to them after they book.
          </Typography>
        </div>
        <div className="mt-4">
          <PhotoUpload />
        </div>
        <div className="mt-4">
          <div className="flex justify-between">
            <Label htmlFor="price-input">Price</Label>
            {!bookableEvent.amountCents && <div className="text-sm text-gray-dark">Optional</div>}
          </div>
          <CurrencyInput
            id="price-input"
            style={{ color: "#0B3954" }}
            value={bookableEvent.amountCents ? formatPrice(bookableEvent.amountCents) : ""}
            onChange={(input) => {
              const newPrice = unformatPrice(input.target.value)
              setBookableEvent((event) => ({ ...event, amountCents: newPrice }))
            }}
          />
        </div>
        <div className="mt-4">
          <div className="flex justify-between">
            <Label htmlFor="capacity-input">Capacity</Label>
            {!bookableEvent.capacity && <div className="text-sm text-gray-dark">Optional</div>}
          </div>
          <Input
            id="capacity-input"
            explanatorySubtext="Maximum number of people who can attend. Leave blank for unlimited."
            value={bookableEvent.capacity || ""}
            type="number"
            step="1"
            onChange={(e) => setBookableEvent((event) => ({ ...event, capacity: Number(e.target.value) }))}
          />
        </div>
      </Flyout>
      <AnimatedModal
        header="Delete event"
        visible={deleteModalVisible}
        hideModal={() => setDeleteModalVisible(false)}
        actionButtonCopy="Yes, delete"
        actionButtonType="destructive"
        saveDisabled={fetching}
        onSave={() => {
          deleteBookableEvent({ id: bookableEvent.id }).then((result) => {
            if (result?.data?.deleteBookableEvent?.result === "success") {
              setBookableEvents(bookableEvents.filter((e) => e.id !== bookableEvent.id))
              setDeleteModalVisible(false)
              setShowFlyout(false)
              showToast(`Your event '${bookableEvent.name}' was deleted successfully`)
            } else {
              console.error(result)
              let errorMessage = "There was an error deleting your event"
              if (result.data?.deleteBookableEvent?.errors)
                errorMessage += `: ${result.data.deleteBookableEvent.errors}`
              showToast({ type: "error", content: errorMessage })
            }
          })
        }}
        showFooter={true}>
        <p>Are you sure you want to delete this event?</p>
      </AnimatedModal>
    </>
  )
}

export default BookableEventsFlyout
