import dayjs from "dayjs"
import React, { useMemo } from "react"

import { useManualBooking } from "../../../contexts/ManualBookingContext"
import DatePicker from "../../shared/DatePicker"
import { Label, TimePicker } from "../../shared/Inputs"

const getTimeFromDateAndTime = (date, time) =>
  dayjs(date)
    .hour(parseInt(time.split(":")[0]))
    .minute(parseInt(time.split(":")[1]))

export default function EditAppointmentDateTime() {
  const { startsAt, setStartsAt, endsAt, setEndsAt } = useManualBooking()

  const startDate = useMemo(() => (startsAt ? dayjs(startsAt).toDate() : dayjs().toDate()), [startsAt])
  const startTime = useMemo(() => {
    if (startsAt) {
      return dayjs(startsAt).format("HH:mm")
    } else {
      const nextHour = dayjs().add(1, "hour").startOf("hour")
      return nextHour.format("HH:mm")
    }
  }, [startsAt])
  const endTime = useMemo(() => {
    if (endsAt) {
      return dayjs(endsAt).format("HH:mm")
    } else {
      const nextHour = dayjs().add(1, "hour").startOf("hour")
      return nextHour.add(60, "minutes").format("HH:mm")
    }
  }, [endsAt])

  // Sometimes startsAt or endsAt is not valid at the first render, so set them to the startDate and times
  if (!dayjs(startsAt).isValid()) {
    const startDateTime = getTimeFromDateAndTime(startDate, startTime)
    setStartsAt(startDateTime.format("YYYY-MM-DDTHH:mm:ssZ"))
  }
  if (!dayjs(endsAt).isValid()) {
    const endDateTime = getTimeFromDateAndTime(startDate, endTime)
    setEndsAt(endDateTime.format("YYYY-MM-DDTHH:mm:ssZ"))
  }

  const handleStartDateChange = (date) => {
    const startDateTime = getTimeFromDateAndTime(date, startTime)
    setStartsAt(startDateTime.format("YYYY-MM-DDTHH:mm:ssZ"))

    const endDateTime = getTimeFromDateAndTime(date, endTime)
    setEndsAt(endDateTime.format("YYYY-MM-DDTHH:mm:ssZ"))
  }

  const handleStartTimeChange = (e) => {
    const startDateTime = getTimeFromDateAndTime(startDate, e.target.value)
    setStartsAt(startDateTime.format("YYYY-MM-DDTHH:mm:ssZ"))

    // Maintain the same duration by adding it to the new start time
    const newEndDateTime = startDateTime.add(duration, "minutes")
    setEndsAt(newEndDateTime.format("YYYY-MM-DDTHH:mm:ssZ"))
  }

  const handleEndTimeChange = (e) => {
    const endDateTime = getTimeFromDateAndTime(startDate, e.target.value)
    setEndsAt(endDateTime.format("YYYY-MM-DDTHH:mm:ssZ"))
  }

  const duration = useMemo(() => {
    const start = dayjs(`2000-01-01 ${startTime}`)
    const end = dayjs(`2000-01-01 ${endTime}`)
    return end.diff(start, "minute")
  }, [startTime, endTime])

  return (
    <div className="flex flex-col gap-2">
      <div className="w-2/5">
        <Label htmlFor="date-picker">Date</Label>
        <DatePicker id="date-picker" selected={startDate} onChange={handleStartDateChange} />
      </div>
      <div className="flex w-full flex-row items-end gap-2">
        <div className="w-2/5">
          <Label htmlFor="start-time-picker">Start Time</Label>
          <TimePicker
            id="start-time-picker"
            showFifteenIncrements={true}
            value={startTime}
            onChange={handleStartTimeChange}
          />
        </div>
        <div className="w-2/5">
          <Label htmlFor="end-time-picker">End Time</Label>
          <TimePicker
            id="end-time-picker"
            showFifteenIncrements={true}
            value={endTime}
            startAt={startTime}
            onChange={handleEndTimeChange}
          />
        </div>
        <div className="w-1/5 pb-2 text-right">
          <span>{duration} mins</span>
        </div>
      </div>
    </div>
  )
}
