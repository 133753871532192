import { EllipsisVerticalIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import DropdownButton from "../../components/shared/DropdownButton"
import HealMeTooltip from "../../components/shared/HealMeTooltip"
import { Box, Divider, ProfilePageEmptyStateBox } from "../../components/shared/Layout"
import { TabNavLink, UnderlinedLink } from "../../components/shared/Links"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"
import DiscountCodesTable from "../DiscountCodes/DiscountCodesTable"
import ProfileEditProvider from "../ProfileEdit/ProfileEditProvider"
import ServiceFlyout from "../ProfileEdit/ServiceFlyout"

import ServiceCard from "./ServiceCard"

const REORDER_SERVICES_MUTATION = `
  mutation ReorderServices($servicesArray: [String!]!) {
    reorderServices(servicesArray: $servicesArray) {
      result
      errors
    }
  }
`

const maxNumberOfServices = 25

const Services = ({
  services: servicesProp,
  discountCodes,
  activeLocations,
  defaultAvailableTimes,
  discountCodesEnabled
}) => {
  const newServiceDefaults = {
    locationIds: [],
    amountCents: 0,
    variations: [],
    name: "",
    description: "",
    public: true
  }
  const newPackageDefaults = { ...newServiceDefaults, package: true, numberOfSessions: 5 }
  const newAddOnDefaults = { ...newServiceDefaults, addOn: true, allowedServiceIds: [] }
  const navItems = discountCodesEnabled
    ? ["Services & Packages", "Add-ons", "Coupons"]
    : ["Services & Packages", "Add-ons"]

  const [services, setServices] = useState(servicesProp.filter((s) => !s.addOn))
  const [addOns, setAddOns] = useState(servicesProp.filter((s) => s.addOn))
  const [service, setService] = useState(newServiceDefaults)
  const [flyoutVisible, setFlyoutVisible] = useState(false)
  const [view, setView] = useState(navItems[0])
  const [showToggleInFlyout, setShowToggleInFlyout] = useState(false)
  const [discountCodesFlyoutVisible, setDiscountCodesFlyoutVisible] = useState(false)
  const [editingDiscountCode, setEditingDiscountCode] = useState(null)

  const { showToast } = useToast()
  const isMobile = window.innerWidth < 768

  const [, reorderServices] = useMutation(REORDER_SERVICES_MUTATION)

  const addNewService = () => {
    setFlyoutVisible(true)
    setService(newServiceDefaults)
    setView("Services & Packages")
  }

  const addNewPackage = () => {
    setFlyoutVisible(true)
    setService(newPackageDefaults)
    setView("Services & Packages")
  }

  const addNewAddOn = () => {
    setFlyoutVisible(true)
    setService(newAddOnDefaults)
    setView("Add-ons")
  }

  const editService = (service) => {
    setService(service)
    setFlyoutVisible(true)
    if (service.addOn) {
      setView("Add-ons")
    } else {
      setView("Services & Packages")
    }
  }

  const addNewDiscountCode = () => {
    setEditingDiscountCode({ editable: true })
    setDiscountCodesFlyoutVisible(true)
    setView("Coupons")
  }

  const unsortableServices =
    view === "Services & Packages" ? services.filter((service) => service.amountCents === 0) : addOns
  const sortableServices = view === "Services & Packages" ? services.filter((service) => service.amountCents > 0) : []

  const dropDownItems = [
    { label: "Service", onClick: addNewService },
    { label: "Package", onClick: addNewPackage },
    { label: "Add-on", onClick: addNewAddOn }
  ]

  if (discountCodesEnabled) {
    dropDownItems.push({ label: "Coupon", onClick: addNewDiscountCode })
  }

  return (
    <>
      <div className="w-full">
        <div className="mb-8 flex items-center justify-between align-bottom sm:mb-4 sm:flex-col sm:items-start">
          <div className="flex flex-col">
            <Typography variant="h3" as="h1">
              My Services
            </Typography>
            <Typography variant="subtitle">Add or edit your services.</Typography>
          </div>
        </div>

        {activeLocations.length === 0 ? (
          <ProfilePageEmptyStateBox title="Services">
            You need to <UnderlinedLink href="/portal/profile/locations">add a location</UnderlinedLink> first in order
            to add services.
          </ProfilePageEmptyStateBox>
        ) : services.length === 0 ? (
          <ProfilePageEmptyStateBox
            title="Services"
            onButtonClick={() => {
              setShowToggleInFlyout(true)
              addNewService()
            }}>
            Add a free service and any paid services you offer.
          </ProfilePageEmptyStateBox>
        ) : (
          <Box data-test-id="services">
            <div className="flex items-center justify-between">
              <div className="mt-4 flex gap-8 border-b border-gray-light sm:mb-4">
                {navItems.map((item) => (
                  <TabNavLink key={item} active={view === item} onClick={() => setView(item)}>
                    {item}
                  </TabNavLink>
                ))}
              </div>
              <div className="flex flex-row sm:hidden" data-tooltip-id="disabled-tooltip">
                <DropdownButton label="Add new" items={dropDownItems} />
              </div>
              {services.length >= maxNumberOfServices && (
                <HealMeTooltip
                  id="disabled-tooltip"
                  place="bottom"
                  content={`Note: You can add a maximum of ${maxNumberOfServices} services.`}
                />
              )}
            </div>
            {services.length < maxNumberOfServices && (
              <Button floatingInMobile={true} onClick={addNewService} className="hidden sm:block">
                Add new
              </Button>
            )}
            {view === "Coupons" && (
              <DiscountCodesTable
                discountCodes={discountCodes}
                services={servicesProp}
                editingCode={editingDiscountCode}
                setEditingCode={setEditingDiscountCode}
                discountCodesFlyoutVisible={discountCodesFlyoutVisible}
                setDiscountCodesFlyoutVisible={setDiscountCodesFlyoutVisible}
              />
            )}
            {view !== "Coupons" && (
              <div className="mt-6 flex max-w-[600px] flex-col gap-4 sm:mt-0">
                {view === "Add-ons" && addOns.length === 0 && (
                  <ProfilePageEmptyStateBox onButtonClick={addNewAddOn}>
                    You don&apos;t have any add-ons yet.
                  </ProfilePageEmptyStateBox>
                )}
                {unsortableServices.map((service) => (
                  <ServiceCard key={service.id} service={service} onClick={() => editService(service)} />
                ))}
                {sortableServices.length > 0 && (
                  <>
                    {unsortableServices.length > 0 ? <Divider className="sm:my-4" /> : null}
                    {isMobile ? (
                      <>
                        {sortableServices.map((service) => (
                          <div key={service.id} className="flex items-center">
                            <ServiceCard service={service} onClick={() => editService(service)} />
                          </div>
                        ))}
                      </>
                    ) : (
                      <ReactSortable
                        className="flex flex-col gap-4"
                        list={sortableServices}
                        setList={(newPositions) => {
                          const newServices = unsortableServices.concat(newPositions)
                          if (JSON.stringify(newServices) !== JSON.stringify(services)) {
                            const servicesArray = newServices.map((service, i) =>
                              JSON.stringify({ id: service.id, position: i + 1 })
                            )
                            reorderServices({ servicesArray }).then((result) => {
                              if (result?.data?.reorderServices?.result !== "success") {
                                console.error(result) // eslint-disable-line no-console
                                showToast({
                                  type: "error",
                                  content:
                                    "There was error reordering your credentials. Please try again later or contact support if the error persists."
                                })
                              }
                            })
                            setServices(newServices)
                          }
                        }}>
                        {sortableServices.map((service) => (
                          <div key={service.id} className="flex cursor-grab items-center">
                            <EllipsisVerticalIcon className="mr-4 h-6 w-6 text-gray-dark" />
                            <ServiceCard service={service} onClick={() => editService(service)} />
                          </div>
                        ))}
                      </ReactSortable>
                    )}
                  </>
                )}
              </div>
            )}
          </Box>
        )}
      </div>

      <ServiceFlyout
        visible={flyoutVisible}
        closeFlyout={() => setFlyoutVisible(false)}
        service={service}
        setService={setService}
        services={services}
        allServices={services}
        setServices={setServices}
        addOns={addOns}
        setAddOns={setAddOns}
        activeLocations={activeLocations}
        defaultAvailableTimes={defaultAvailableTimes}
        setView={setView}
        showToggle={showToggleInFlyout}
      />
    </>
  )
}

export default function PortalServicesPage({ profileStatusData, ...props }) {
  return (
    <ProfileEditProvider profileStatusData={profileStatusData}>
      <Services {...props} />
    </ProfileEditProvider>
  )
}
