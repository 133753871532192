export const UPSERT_BOOKABLE_EVENT_MUTATION = `
  mutation UpsertBookableEvent(
    $id: ID,
    $name: String!,
    $startsAt: String!,
    $endsAt: String!,
    $allDay: Boolean,
    $timeZone: String!,
    $description: String,
    $instructions: String,
    $capacity: Int,
    $amountCents: Int,
    $location: String!
  ) {
    upsertBookableEvent(
      id: $id,
      name: $name,
      startsAt: $startsAt,
      endsAt: $endsAt,
      allDay: $allDay,
      timeZone: $timeZone,
      description: $description,
      instructions: $instructions,
      capacity: $capacity,
      amountCents: $amountCents,
      location: $location
    ) {
      result
      errors
      bookableEvent {
        id name startsAt endsAt allDay timeZone photoUrl location description instructions amountCents capacity
      }
    }
  }
`

export const UPDATE_BOOKABLE_EVENT_PHOTO_MUTATION = `
  mutation UpdateBookableEventPhoto($handle: String!, $id: ID!) {
    updateBookableEventPhoto(handle: $handle, id: $id) { result errors photoUrl }
  }
`

export const REMOVE_BOOKABLE_EVENT_PHOTO_MUTATION = `
  mutation RemoveBookableEventPhoto($id: ID!) {
    removeBookableEventPhoto(id: $id) { result errors }
  }
`

export const DELETE_BOOKABLE_EVENT_MUTATION = `
  mutation DeleteBookableEvent($id: ID!) {
    deleteBookableEvent(id: $id) { result errors }
  }
`
