import { VideoCameraIcon } from "@heroicons/react/24/outline"
import React, { useEffect } from "react"

import { useManualBooking } from "../../../../contexts/ManualBookingContext"
import { usePractice } from "../../../../contexts/PracticeContext"
import { Label, Select } from "../../../shared/Inputs"

const LocationSelect = () => {
  const { practice } = usePractice()
  const { appointment, locationId, setLocationId } = useManualBooking()

  // Set default location to first location if no location is selected
  useEffect(() => {
    if (!locationId && practice?.locations?.length > 0) {
      setLocationId(practice.locations[0].id)
    }
  }, [locationId, practice])

  const virtualLink = appointment?.virtualMeetingLinkWithFallback

  return (
    <div className="mt-4">
      <Label htmlFor="location-select">Location</Label>
      <Select
        id="location-select"
        options={practice?.locations?.map((location) => ({ label: location.singleLine, value: location.id }))}
        onChange={(e) => setLocationId(e.target.value)}
        value={locationId}
      />
      {appointment && virtualLink && (
        <div className="mt-2 text-sm">
          <VideoCameraIcon className="mr-2 inline h-4 w-4" />
          <a href={virtualLink} target="_blank" rel="noopener noreferrer" className="text-teal">
            Join virtual meeting
          </a>
        </div>
      )}
    </div>
  )
}

export default LocationSelect
