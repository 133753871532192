import React, { createContext, useContext, useEffect, useState } from "react"

import { formatPhone, urqlClient } from "../utils/utils"

const PRACTICE_QUERY = `
  query CurrentPracticeContext {
    currentPractice {
      id
      recurringBookingsEnabled
      packagesEnabled
      clientNotesEnabled
      user { phone email }
      hasPaymentProvider
      paymentProvider
      conferencingServices {
        provider
        active
      }
      locations {
        id
        singleLine
      }
    }
  }
`

export const PracticeContext = createContext()

export const PracticeProvider = ({ children }) => {
  const [practice, setPractice] = useState({})
  const [practiceServices, setPracticeServices] = useState(null)

  const fetchPractice = () => {
    urqlClient
      .query(PRACTICE_QUERY)
      .toPromise()
      .then((res) => {
        if (res.data?.currentPractice) {
          setPractice(res.data.currentPractice)
        } else {
          console.error("Error fetching practice data", res) // eslint-disable-line no-console
        }
      })
  }

  useEffect(() => {
    fetchPractice()
  }, [])

  const practiceEmail = practice.user?.email
  const practicePhone = formatPhone(practice.user?.phone)

  return (
    <PracticeContext.Provider
      value={{
        practice,
        setPractice,
        practiceEmail,
        practicePhone,
        fetchPractice,
        practiceServices,
        setPracticeServices
      }}>
      {children}
    </PracticeContext.Provider>
  )
}

export const usePractice = () => useContext(PracticeContext)
