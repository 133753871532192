import React from "react"

import Badge from "../../components/shared/Badge"
import { Button } from "../../components/shared/Buttons"
import { Box } from "../../components/shared/Layout"
import Typography from "../../components/shared/Typography"
import { formatPrice } from "../../utils/utils"
import DisplayAvailableTimes from "../ProfileEdit/DisplayAvailableTimes"

const ServiceCard = ({ service, onClick }) => {
  const amount = service.amountCents === 0 ? "$0" : formatPrice(service.amountCents)

  return (
    <Box key={service.id} className="w-full">
      {service.package && (
        <Badge type="info" className="mb-2 inline-block">
          Package
        </Badge>
      )}
      <div className="flex items-center justify-between">
        <Typography variant="title">{service.name}</Typography>
        <Button type="link" onClick={onClick}>
          Edit
        </Button>
      </div>
      <Typography variant="smSubtitle">
        {service.addOn ? amount : `${service.timeLength} min • ${amount}`}
        {service.package && ` (${service.numberOfSessions} sessions)`}
      </Typography>
      <div className="mt-4 flex justify-between gap-4">
        <div
          className="wysiwyg-content text-sm"
          dangerouslySetInnerHTML={{
            __html: service.description.substring(0, 190) + (service.description.length > 190 ? "..." : "")
          }}
        />
        {service.smallWebpUrl && (
          <img src={service.smallWebpUrl} alt={service.name} className="h-16 w-16 flex-none rounded-lg" />
        )}
      </div>
      {service.availableTimesEnabled && (
        <DisplayAvailableTimes
          locationOrService={service}
          className="-m-8 mt-8 box-content rounded-b-xl bg-gray-ultralight p-8 text-sm"
        />
      )}
    </Box>
  )
}

export default ServiceCard
