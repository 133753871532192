import dayjs from "dayjs"
import React, { createContext, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useMutation } from "urql"

import { getFirstAvailableTimeSlot } from "../@core/practice/practice.utils"
import {
  APPOINTMENT_QUERY_FOR_CALENDAR,
  PRO_CALENDAR_EVENTS_QUERY,
  PRO_CALENDAR_QUERY,
  PRO_CALENDAR_USER_QUERY
} from "../components/pro_portal/calendar/appointment_graphql"
import { formatEventTitle } from "../utils/calendarUtils"
import { UPSERT_PERSONAL_EVENT } from "../utils/mutations"
import { urqlClient } from "../utils/utils"

import { useManualBooking } from "./ManualBookingContext"
import { usePractice } from "./PracticeContext"
import { useToast } from "./ToastContext"

const ProCalendarContext = createContext()

const TIME_GRID_TYPE_MAP = {
  timeGridDay: "day",
  timeGridWeek: "week",
  dayGridMonth: "month"
}

export const ProCalendarProvider = ({ children }) => {
  const [calendarData, setCalendarData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [calendarEvents, setCalendarEvents] = useState([])
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState()
  const [selectedPersonalEvent, setSelectedPersonalEvent] = useState(null)
  const [selectedExternalEvent, setSelectedExternalEvent] = useState(null)
  const [currentViewDate, setCurrentViewDate] = useState(null)
  const [showRescheduleConfirm, setShowRescheduleConfirm] = useState(false)
  const [draggedEvent, setDraggedEvent] = useState(null)

  const { showToast } = useToast()
  const navigate = useNavigate()

  const {
    setAppointment: setSelectedAppointment,
    setRescheduledStartsAt,
    setRescheduledEndsAt,
    resetBookingState,
    setStartsAt,
    setEndsAt
  } = useManualBooking()

  const { practice } = usePractice()

  const [, upsertPersonalEvent] = useMutation(UPSERT_PERSONAL_EVENT)

  useEffect(() => {
    if (window.location.hash) {
      const param = window.location.hash.substring(1)
      if (param === "create-appointment") {
        handleCreateAppointmentButtonClick()
      } else {
        urqlClient
          .query(APPOINTMENT_QUERY_FOR_CALENDAR, { instantActionToken: param })
          .toPromise()
          .then((result) => {
            if (result.data?.tokenAppointment) {
              const appointment = result.data.tokenAppointment
              setSelectedAppointment(appointment)
              setIsFlyoutVisible(true)
            } else {
              showToast({ type: "error", content: "Appointment not found" })
            }
          })
      }
    }
  }, [])

  const fetchCalendarUser = () => {
    urqlClient
      .query(PRO_CALENDAR_USER_QUERY)
      .toPromise()
      .then((result) => {
        if (result.data) {
          setCalendarData((prevData) => ({
            ...prevData,
            currentUser: result.data.currentUser,
            currentPractice: result.data.currentPractice
          }))
        }
      })
  }

  const mergeUniqueById = (existingItems = [], newItems = []) =>
    [...existingItems, ...newItems].filter((item, index, self) => index === self.findIndex((i) => i.id === item.id))

  const fetchCalendarData = ({ from, to }) => {
    urqlClient
      .query(PRO_CALENDAR_QUERY, { from, to })
      .toPromise()
      .then((result) => {
        if (result.data) {
          setCalendarData((prevData) => ({
            ...prevData,
            practiceAppointments: mergeUniqueById(prevData?.practiceAppointments, result.data.practiceAppointments),
            personalEvents: mergeUniqueById(prevData?.personalEvents, result.data.personalEvents)
          }))
          setIsLoading(false)
        }
      })
  }

  const fetchCalendarEvents = (startDate, endDate) => {
    urqlClient
      .query(PRO_CALENDAR_EVENTS_QUERY, { from: startDate, to: endDate })
      .toPromise()
      .then((result) => {
        if (result.data?.calendarEvents) {
          const processedEvents = processCalendarEvents(result.data.calendarEvents)
          setCalendarEvents((prevEvents) => mergeUniqueById(prevEvents, processedEvents))
        }
      })
  }

  const processCalendarEvents = (events) => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return events.map((event) => {
      if (event.id.startsWith("personal-event")) {
        return processPersonalEvent(event, currentTimeZone)
      } else if (event.allDay) {
        return processAllDayEvent(event)
      }
      return event
    })
  }

  const processPersonalEvent = (event, currentTimeZone) => ({
    ...event,
    allDay: event.extendedProps.timeZone && event.extendedProps.timeZone !== currentTimeZone ? false : event.allDay
  })

  const processAllDayEvent = (event) => ({
    ...event,
    start: event.start.split("T")[0],
    end: event.end.split("T")[0],
    allDay: true
  })

  useEffect(() => {
    const currentWeekStart = dayjs().startOf("week").subtract(1, "week").format("YYYY-MM-DD")
    const currentWeekEnd = dayjs().endOf("week").add(1, "week").format("YYYY-MM-DD")
    fetchCalendarUser()
    fetchCalendarData({ from: currentWeekStart, to: currentWeekEnd })
    fetchCalendarEvents(currentWeekStart, currentWeekEnd)
  }, [])

  const handleEventClick = (eventInfo) => {
    if (isLoading) return

    if (eventInfo.event.extendedProps.token) {
      const appointment = findAppointment(eventInfo.event.extendedProps.token)
      setSelectedAppointment(appointment)
      setIsFlyoutVisible(true)
      navigate(`${window.location.pathname}#${eventInfo.event.extendedProps.token}`)
    } else if (eventInfo.event.extendedProps.personalEventId) {
      const personalEvent = findPersonalEvent(eventInfo.event.id)
      setSelectedPersonalEvent(personalEvent)
      setIsFlyoutVisible(true)
    } else {
      setSelectedExternalEvent(eventInfo.event)
      setIsFlyoutVisible(true)
    }
  }

  const handleDateClick = (dateInfo) => {
    const clickedDate = dayjs(dateInfo.date).format("YYYY-MM-DDTHH:mm:ssZ")
    setSelectedDateTime(clickedDate)
    setStartsAt(clickedDate)
    setEndsAt(dayjs(clickedDate).add(60, "minutes").format("YYYY-MM-DDTHH:mm:ssZ"))
    setIsFlyoutVisible(true)
    setSelectedAppointment(null)
    setSelectedPersonalEvent(null)
    addTemporaryEvent(clickedDate)
  }

  const addTemporaryEvent = (startDate) => {
    setCalendarEvents((prevEvents) => [
      ...prevEvents.filter((event) => event.id !== "temp-1"),
      {
        id: "temp-1",
        start: startDate,
        end: dayjs(startDate).add(1, "hours").format("YYYY-MM-DDTHH:mm:ssZ"),
        title: "Draft Appointment",
        backgroundColor: "#55778A",
        borderColor: "#55778A"
      }
    ])
  }

  const handleDragReschedule = (eventDropInfo) => {
    const { event } = eventDropInfo
    setDraggedEvent(eventDropInfo)

    if (event.extendedProps.personalEventId) {
      handlePersonalEventReschedule(event)
    } else if (event.extendedProps.token) {
      handleAppointmentReschedule(event)
    }
  }

  const handlePersonalEventReschedule = (event) => {
    upsertPersonalEvent({
      id: event.extendedProps.personalEventId,
      startsAt: event.start,
      endsAt: event.end
    }).then((response) => {
      if (response.data.upsertPersonalEvent.result === "success") {
        closeFlyout()
        showToast("Personal event updated successfully.")
      } else {
        console.error(response)
      }
    })
  }

  const handleAppointmentReschedule = (event) => {
    const appointment = findAppointment(event.extendedProps.token)
    setSelectedAppointment(appointment)
    // Need these other states instead of startsAt and endsAt, because setting the appointment above also changes startsAt and endsAt
    setRescheduledStartsAt(dayjs(event.start).format("YYYY-MM-DDTHH:mm:ssZ"))
    setRescheduledEndsAt(dayjs(event.end).format("YYYY-MM-DDTHH:mm:ssZ"))
    setShowRescheduleConfirm(true)
  }

  const hideRescheduleConfirm = () => {
    setShowRescheduleConfirm(false)
    if (draggedEvent) {
      draggedEvent.revert() // Revert the dragged event to its original position
      setDraggedEvent(null)
    }
  }

  const findPersonalEvent = (id) => calendarEvents.find((event) => event.id === id)
  const findAppointment = (token) =>
    calendarData.practiceAppointments.find((event) => event.instantActionToken === token)

  const closeFlyout = () => {
    setIsFlyoutVisible(false)
    setSelectedAppointment(null)
    setSelectedPersonalEvent(null)
    setSelectedExternalEvent(null)
    setSelectedDateTime(null)
    setCalendarEvents((prevEvents) => prevEvents.filter((event) => event.id !== "temp-1"))
    resetBookingState()
    const currentPath = window.location.pathname + window.location.search
    navigate(currentPath, { replace: true })
  }

  const handleCreatePersonalEvent = (personalEvent) => {
    const newEvent = {
      id: `personal-event-${personalEvent.id}`,
      start: personalEvent.startsAt,
      end: personalEvent.endsAt,
      title: personalEvent.name || "Personal Event",
      borderColor: "#C8D6DF",
      backgroundColor: "#C8D6DF",
      classNames: ["personal-event"],
      allDay: personalEvent.allDay,
      extendedProps: {
        personalEventId: personalEvent.id,
        duration: (new Date(personalEvent.endsAt) - new Date(personalEvent.startsAt)) / 1000 / 60
      }
    }
    setCalendarEvents((prevEvents) => [
      ...prevEvents.filter((event) => event.id !== `personal-event-${personalEvent.id}`),
      newEvent
    ])
    showToast(`Personal event ${selectedPersonalEvent ? "updated" : "created"} successfully.`)
    closeFlyout()
  }

  const handleDeletePersonalEvent = (personalEventId) => {
    setCalendarEvents((prevEvents) => prevEvents.filter((event) => event.id !== `personal-event-${personalEventId}`))
    showToast({ type: "info", content: "Your personal event has been cancelled" })
    closeFlyout()
  }

  const handleAppointmentChange = (appointment) => {
    const newAppointment = {
      id: `appointment-${appointment.id}`,
      start: appointment.startsAt,
      end: appointment.endsAt,
      title: formatEventTitle(appointment),
      borderColor: "#55778A",
      backgroundColor: "#55778A",
      classNames: ["primary-event"],
      extendedProps: {
        token: appointment.instantActionToken,
        duration: (new Date(appointment.endsAt) - new Date(appointment.startsAt)) / 1000 / 60,
        description: appointment.appointmentServices[0].service.name
      }
    }
    setCalendarEvents((prevEvents) => [
      ...prevEvents.filter((event) => event.id !== `appointment-${appointment.id}`),
      newAppointment
    ])
    setCalendarData((prevData) => ({
      ...prevData,
      practiceAppointments: [
        ...(prevData.practiceAppointments ?? []).filter((appt) => appt.id !== appointment.id),
        appointment
      ]
    }))
    closeFlyout()
  }

  const handleRecurringAppointmentsChange = (appointments) => {
    const newAppointments = appointments.map((appointment) => ({
      id: `appointment-${appointment.id}`,
      start: appointment.startsAt,
      end: appointment.endsAt,
      title: formatEventTitle(appointment),
      borderColor: "#55778A",
      backgroundColor: "#55778A",
      classNames: ["primary-event"],
      extendedProps: {
        token: appointment.instantActionToken,
        duration: (new Date(appointment.endsAt) - new Date(appointment.startsAt)) / 1000 / 60,
        description: appointment.appointmentServices[0].service.name
      }
    }))
    setCalendarEvents((prevEvents) => [
      ...prevEvents.filter((event) => !newAppointments.map((appt) => `appointment-${appt.id}`).includes(event.id)),
      ...newAppointments
    ])
    setCalendarData((prevData) => ({
      ...prevData,
      practiceAppointments: [
        ...prevData.practiceAppointments.filter(
          (appointment) => !appointments.map((appt) => appt.id).includes(appointment.id)
        ),
        ...appointments
      ]
    }))
    closeFlyout()
  }

  const handleAppointmentCancellation = (appointmentIds) => {
    setCalendarEvents((prevEvents) =>
      prevEvents.filter((event) => !appointmentIds.map((id) => `appointment-${id}`).includes(event.id))
    )
    setCalendarData((prevData) => ({
      ...prevData,
      practiceAppointments: prevData.practiceAppointments.filter(
        (appointment) => !appointmentIds.includes(appointment.id)
      )
    }))
    closeFlyout()
  }

  const handleRescheduleSuccess = (oldAppointmentId, updatedAppointment) => {
    const newCalendarEvent = {
      id: `appointment-${updatedAppointment.id}`,
      start: updatedAppointment.startsAt,
      end: updatedAppointment.endsAt,
      title: formatEventTitle(updatedAppointment),
      borderColor: "#55778A",
      backgroundColor: "#55778A",
      classNames: ["primary-event"],
      extendedProps: {
        token: updatedAppointment.instantActionToken,
        duration: (new Date(updatedAppointment.endsAt) - new Date(updatedAppointment.startsAt)) / 1000 / 60,
        description: updatedAppointment.appointmentServices[0].service.name
      }
    }
    setCalendarEvents((prevEvents) => [
      ...prevEvents.filter((appointment) => appointment.id !== `appointment-${oldAppointmentId}`),
      newCalendarEvent
    ])
    setCalendarData((prevData) => ({
      ...prevData,
      practiceAppointments: [
        ...prevData.practiceAppointments.filter((appointment) => appointment.id !== oldAppointmentId),
        updatedAppointment
      ]
    }))
    closeFlyout()
  }

  // This is called when the "Create appointment" button is clicked
  const handleCreateAppointmentButtonClick = () => {
    setIsFlyoutVisible(true)
    if (currentViewDate) {
      // When the calendar is set to "Day" view
      setStartsAt(dayjs(currentViewDate).format("YYYY-MM-DDTHH:mm:ssZ"))
      setEndsAt(dayjs(currentViewDate).add(60, "minutes").format("YYYY-MM-DDTHH:mm:ssZ"))
    } else if (practice?.bookingAvailability?.availableSlots) {
      const firstAvailableTimeSlot = getFirstAvailableTimeSlot(practice.bookingAvailability.availableSlots)
      setStartsAt(dayjs(firstAvailableTimeSlot).format("YYYY-MM-DDTHH:mm:ssZ"))
      setEndsAt(dayjs(firstAvailableTimeSlot).add(60, "minutes").format("YYYY-MM-DDTHH:mm:ssZ"))
    } else {
      // Fallback to today's date
      const nextHour = dayjs().add(1, "hour").startOf("hour")
      setStartsAt(nextHour.format("YYYY-MM-DDTHH:mm:ssZ"))
      setEndsAt(nextHour.add(60, "minutes").format("YYYY-MM-DDTHH:mm:ssZ"))
    }
  }

  // This is called when the date range is changed
  const handleDatesSet = (dateInfo) => {
    const timeGridType = TIME_GRID_TYPE_MAP[dateInfo.view.type] || "week"
    const from = dayjs(dateInfo.startStr).subtract(1, timeGridType).format("YYYY-MM-DD")
    const to = dayjs(dateInfo.endStr).add(1, timeGridType).format("YYYY-MM-DD")
    fetchCalendarData({ from, to })
    fetchCalendarEvents(from, to)
    if (dateInfo.view.type === "timeGridDay") {
      const clickedTime = dayjs(dateInfo.start).format("YYYY-MM-DDTHH:mm:ssZ")
      setCurrentViewDate(dayjs(dateInfo.start).format("YYYY-MM-DD"))
      setStartsAt(clickedTime)
      setEndsAt(dayjs(clickedTime).add(60, "minutes").format("YYYY-MM-DDTHH:mm:ssZ"))
    } else {
      setCurrentViewDate(null)
    }
  }

  const value = {
    calendarData,
    isLoading,
    calendarEvents,
    isFlyoutVisible,
    selectedDateTime,
    selectedPersonalEvent,
    selectedExternalEvent,
    currentViewDate,
    showRescheduleConfirm,
    setCurrentViewDate,
    handleEventClick,
    handleDateClick,
    handleDragReschedule,
    closeFlyout,
    setIsFlyoutVisible,
    setCalendarEvents,
    setCalendarData,
    handleCreatePersonalEvent,
    handleDeletePersonalEvent,
    handleAppointmentChange,
    handleRecurringAppointmentsChange,
    handleAppointmentCancellation,
    handleRescheduleSuccess,
    hideRescheduleConfirm,
    handleCreateAppointmentButtonClick,
    handleDatesSet,
    draggedEvent,
    setDraggedEvent
  }

  return <ProCalendarContext.Provider value={value}>{children}</ProCalendarContext.Provider>
}

export const useProCalendar = () => useContext(ProCalendarContext)
