import Honeybadger from "@honeybadger-io/js"
import { payments } from "@square/web-sdk"
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { createContext, useCallback, useContext, useEffect } from "react"
import { useMutation } from "urql"

import useStripeHook from "../../hooks/useStripeHook"
import { SQUARE_ADD_CARD_TO_CUSTOMER_MUTATION } from "../../utils/mutations"

export default function PaymentProviderWrapper({ practice, appointment, user, client, children }) {
  return (
    <>
      {practice.paymentProvider === "stripe" && (
        <StripeWrapper practice={practice} appointment={appointment} user={user} client={client}>
          {children}
        </StripeWrapper>
      )}
      {practice.paymentProvider === "square" && (
        <SquareWrapper practice={practice} appointment={appointment} user={user} client={client}>
          {children}
        </SquareWrapper>
      )}
    </>
  )
}

function StripeWrapper({ children, practice, user, client, appointment }) {
  const { stripeData, setupIntent, elementsOptions } = useStripeHook()

  const promise = loadStripe(`${window.App.stripe_key}`, {
    stripeAccount: practice.stripeAccountId || window.App.practice_stripe_account
  })

  useEffect(() => {
    setupIntent({
      practiceId: practice.id,
      appointmentId: appointment?.id,
      userId: user?.id,
      clientId: client?.id
    }).then((res) => {
      if (res.data?.setupIntent?.result === "success") {
        // nothing
      } else {
        Honeybadger.notify(res.error)
        console.error(res.error) // eslint-disable-line no-console
      }
    })
  }, [])

  return (
    stripeData &&
    promise && (
      <Elements stripe={promise} options={elementsOptions}>
        <PaymentProviderContextProvider provider="stripe">{children}</PaymentProviderContextProvider>
      </Elements>
    )
  )
}

function SquareWrapper({ practice, appointment, user, client, children }) {
  return (
    <PaymentProviderContextProvider
      provider="square"
      appointment={appointment}
      practice={practice}
      user={user}
      client={client}>
      {children}
    </PaymentProviderContextProvider>
  )
}

export const PaymentProviderContext = createContext()

export const PaymentProviderContextProvider = ({ provider, practice, appointment, user, client, children }) => {
  const [squareCard, setSquareCard] = React.useState(null)
  const [, squareAddCardToCustomer] = useMutation(SQUARE_ADD_CARD_TO_CUSTOMER_MUTATION)

  const stripe = provider === "stripe" ? useStripe() : null
  const elements = provider === "stripe" ? useElements() : null

  const [squarePayments, setSquarePayments] = React.useState(null)
  useEffect(() => {
    const initializePayment = async () => {
      let sp = await payments(window.App.square_application_id, "")
      setSquarePayments(sp)
    }
    initializePayment()
  }, [])

  const handleCardSavedOnProvider = useCallback(async () => {
    if (provider === "stripe") {
      if (!stripe || !elements) return
      const result = await stripe.confirmSetup({
        elements,
        redirect: "if_required"
      })
      if (result.error) {
        return { error: result.error.message || "An error occurred" }
      }
      return { token: null }
    } else if (provider === "square") {
      try {
        const tokenizeResult = await squareCard.tokenize()
        if (tokenizeResult.status === "OK") {
          const addCardResult = await squareAddCardToCustomer({
            clientId: client?.id || appointment?.client?.id,
            practiceId: practice.id,
            sourceId: tokenizeResult.token,
            userId: user?.id || appointment?.user?.id
          })

          if (addCardResult.data?.squareAddCardToCustomer?.result === "success") {
            return { token: tokenizeResult.token }
          }
          return { error: addCardResult.data?.squareAddCardToCustomer?.errors.join(", ") || "An error occurred" }
        } else {
          return { error: JSON.stringify(tokenizeResult.errors) }
        }
      } catch (error) {
        return { error: error.message || "An error occurred" }
      }
    }
  }, [provider, squareCard, stripe, elements])

  return (
    <PaymentProviderContext.Provider
      value={{ handleCardSavedOnProvider, stripe, elements, squarePayments, squareCard, setSquareCard }}>
      {children}
    </PaymentProviderContext.Provider>
  )
}

export const usePaymentProvider = () => useContext(PaymentProviderContext)
