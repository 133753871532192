export const APPOINTMENT_FIELDS_FOR_CALENDAR = `
  id
  isVirtual
  virtualMeetingLinkWithFallback
  startsAt
  endsAt
  amountCents
  tipAmountCents
  fullTotal
  paymentIntentId
  timeZone
  state
  pendingOrApproved
  recurrenceFrequency
  recurrenceCount
  recurrenceUntil
  instantActionToken
  bookedBy
  createdAt
  newClient
  markPaid
  paymentStatus
  paymentError
  paymentMethod
  packageSessionNumber
  package { id numberOfSessions name createdAt }
  adjustments { id adjustedAt amountCents note }
  reviewLink
  appointmentServices {
    amountCents
    service {
      id
      serviceId
      name
      amountCents
      timeLength
      package
    }
  }
  location {
    id
    name
  }
  client {
    id
    firstName
    lastName
    email
    phone
    deletedAt
    customPrices { id amountCents serviceId }
  }
  discountCodeRedemptions { code amountCents }
`

export const PRACTICE_QUERY = `
  query CurrentPractice($includePrivate: Boolean, $includeAddOns: Boolean) {
    currentPractice {
      id
      recurringBookingsEnabled
      isStripeApproved
      services(includePrivate: $includePrivate, includeAddOns: $includeAddOns) {
        id
        name
        description
        photoUrl
        package
        numberOfSessions
        addOn
        locations {
          id
          name
          latitude
          longitude
          singleLine
          cityState
          kind
        }
        variations {
          id
          timeLength
          amountCents
        }
      }
      locations {
        id
        name
        latitude
        longitude
        singleLine
        cityState
        virtualLink
        virtualInstructions
        kind
        services {
          id
          name
          description
          photoUrl
          variations {
            id
            timeLength
            amountCents
          }
        }
      }
      bookingAvailability {
        timeZone
        availableSlots {
          date
          datetimes
        }
      }
    }
  }
`

export const APPOINTMENT_QUERY = `
  query TokenAppointment($instantActionToken: String!) {
    tokenAppointment(instantActionToken: $instantActionToken) {
      id
      startsAt
      endsAt
      hasTextReview
      hasVideoReview
      hasEndorsements
      state
      timeZone
      isVirtual
      createdAt
      amountCents
      tipAmountCents
      fullTotal
      pendingOrApproved
      paymentIntentId
      paymentMethod
      hasPaymentMethod
      paymentError
      markPaid
      instantActionToken
      bookedBy
      createdAt
      newClient
      paymentStatus
      packageSessionNumber
      reviewLink
      adjustments { id adjustedAt amountCents note }
      package { id numberOfSessions name createdAt }
      defaultPaymentMethod {
        label
        brand
        expiry
      }
      practice {
        id
        isStripeApproved
        user {
          id
          name
          profilePhotos {
            medium {
              jpeg
              webp
            }
            small {
              jpeg
              webp
            }
            original {
              jpeg
              webp
            }
          }
        }
      }
      service {
        id
        name
        description
        photoUrl
        timeLength
        parent {
          id
          name
          description
          photoUrl
          timeLength
        }
      }
      appointmentServices {
        amountCents
        service {
          id
          serviceId
          name
          amountCents
          timeLength
          package
        }
      }
      location {
        id
        name
        latitude
        longitude
        singleLine
        cityState
        virtualLink
        virtualInstructions
      }
      client {
        id
        firstName
        lastName
        userId
        email
        phone
        deletedAt
      }
      discountCodeRedemptions { code amountCents }
    }
  }
`

export const APPOINTMENT_QUERY_FOR_CALENDAR = `
  query AppointmentForCalendar($instantActionToken: String!) {
    tokenAppointment(instantActionToken: $instantActionToken) { ${APPOINTMENT_FIELDS_FOR_CALENDAR} }
  }
`

export const PRO_CALENDAR_USER_QUERY = `
  query ProCalendarUser {
    currentUser {
      commsName email phone
      practice {
        id
        hasImportedClients
        showTermsModal
        allHours {
          weekDay
          hours {
            startsAt
            endsAt
          }
        }
      }
    }
    currentPractice { id }
  }
`

export const PRO_CALENDAR_QUERY = `
  query ProCalendar($from: String, $to: String) {
    practiceAppointments(from: $from, to: $to) { ${APPOINTMENT_FIELDS_FOR_CALENDAR} }
    personalEvents(from: $from, to: $to) { id name startsAt endsAt allDay }
  }
`

export const PRO_CALENDAR_EVENTS_QUERY = `
  query ProCalendarEvents($from: String, $to: String) {
    calendarEvents(from: $from, to: $to) {
      id title start end allDay backgroundColor borderColor textColor classNames editable
      extendedProps { personalEventId description token duration about calendarName location timeZone }
    }
  }
`

export const CREATE_APPOINTMENT_MUTATION = `
  mutation ProCreateAppointment(
    $appointmentServices: [AppointmentServiceInput!]!,
    $locationId: ID!,
    $startsAt: String!,
    $endsAt: String,
    $clientId: ID,
    $client: ClientInput,
  ) {
    proCreateAppointment(
      appointmentServices: $appointmentServices,
      locationId: $locationId,
      startsAt: $startsAt,
      endsAt: $endsAt,
      clientId: $clientId,
      client: $client,
    ) {
      appointment { ${APPOINTMENT_FIELDS_FOR_CALENDAR} }
      result
      errors
    }
  }
`

export const CREATE_APPOINTMENTS_MUTATION = `
  mutation CreateAppointments(
    $appointmentServices: [AppointmentServiceInput!]!,
    $locationId: ID!,
    $clientId: ID,
    $client: ClientInput,
    $bookedFrom: String,
    $bookedBy: String,
    $recurringStartTimes: [String!]!,
    $recurrencePattern: String!,
    $duration: Int
  ) {
    createAppointments(
      appointmentServices: $appointmentServices,
      locationId: $locationId,
      clientId: $clientId,
      client: $client,
      bookedFrom: $bookedFrom,
      bookedBy: $bookedBy,
      recurringStartTimes: $recurringStartTimes,
      recurrencePattern: $recurrencePattern,
      duration: $duration
    ) {
      appointments { ${APPOINTMENT_FIELDS_FOR_CALENDAR} }
      result
      errors
    }
  }
`

export const UPDATE_APPOINTMENT_MUTATION = `
  mutation UpdateAppointment(
    $appointmentId: ID!,
    $state: String,
    $appointmentServices: [AppointmentServiceInput!]!,
    $locationId: ID,
    $clientType: String,
    $startsAt: String,
    $endsAt: String,
    $practitionerNote: String,
  ) {
    updateAppointment(
      appointmentId: $appointmentId,
      state: $state,
      appointmentServices: $appointmentServices,
      locationId: $locationId,
      clientType: $clientType,
      startsAt: $startsAt,
      endsAt: $endsAt,
      practitionerNote: $practitionerNote
    ) {
      result
      errors
      appointment { ${APPOINTMENT_FIELDS_FOR_CALENDAR} }
    }
  }
`

export const UPDATE_APPOINTMENT_V2_MUTATION = `
  mutation UpdateAppointmentV2(
    $appointmentId: ID!,
    $appointmentServices: [AppointmentServiceInput!],
    $locationId: ID,
    $practitionerNote: String,
    $startsAt: String,
    $endsAt: String,
  ) {
    updateAppointmentV2(
      appointmentId: $appointmentId,
      appointmentServices: $appointmentServices,
      locationId: $locationId,
      practitionerNote: $practitionerNote,
      startsAt: $startsAt,
      endsAt: $endsAt,
    ) {
      result
      errors
      appointment { ${APPOINTMENT_FIELDS_FOR_CALENDAR} }
    }
  }
`

export const CANCEL_APPOINTMENT_MUTATION = `
  mutation CancelAppointment($appointmentId: ID!, $practitionerNote: String, $cancelRecurringSeries: Boolean) {
    cancelAppointment(appointmentId: $appointmentId, practitionerNote: $practitionerNote, cancelRecurringSeries: $cancelRecurringSeries) {
      result
      errors
      cancelledAppointmentIds
    }
  }
`

export const ACCEPT_APPOINTMENT_MUTATION = `
  mutation AcceptAppointment($appointmentId: ID!) {
    acceptAppointment(appointmentId: $appointmentId) {
      result
      errors
      appointment { ${APPOINTMENT_FIELDS_FOR_CALENDAR} }
    }
  }
`

export const DECLINE_APPOINTMENT_MUTATION = `
  mutation DeclineAppointment($appointmentId: ID!) {
    declineAppointment(appointmentId: $appointmentId) {
      result
      errors
      appointment { ${APPOINTMENT_FIELDS_FOR_CALENDAR} }
    }
  }
`

export const RETRY_PAYMENT_MUTATION = `
  mutation RetryPayment($appointmentId: ID!) {
    retryPayment(appointmentId: $appointmentId) {
      result
      errors
    }
  }
`

export const RESEND_REVIEW_REQUEST = `
  mutation ResendReviewRequest($appointmentId: ID!) {
    resendReviewRequest(appointmentId: $appointmentId) {
      result
      errors
    }
  }
`

export const MARK_AS_PAID = `
  mutation MarkPaid($appointmentId: ID!, $paidBy: String){
    markPaid(appointmentId: $appointmentId, paidBy: $paidBy){
      result
      errors
    }
  }
`

export const UNDO_MARK_PAID = `
  mutation UndoMarkPaid($appointmentId: ID!){
    undoMarkPaid(appointmentId: $appointmentId){
      result
      errors
    }
  }
`
